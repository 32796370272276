<template>
  <section>
    <b-card
      no-body
      class="coupon-card"
    >
      <b-card-header>
        <div
          class="d-flex flex-wrap justify-content-between align-items-center mb-2 w-100"
          style="gap: 8px;"
        >
          <div>
            <h4>{{ $t('List of goods receipts') }}</h4>
          </div>
        </div>
        <div class="d-flex justify-content-between w-100 cus-group-filter">
          <div class="flex-1">
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>
              <b-form-input
                v-model="modelFilter.search"
                :placeholder="$t('Search')"
                type="text"
                class=""
                @keyup.enter="handleFilter"
              />
            </b-input-group>
          </div>
          <div class="d-flex cus-group">
            <flat-pickr
              v-model="modelFilter.created_at"
              class="form-control"
              :config="{ dateFormat: 'd-m-Y' }"
              :placeholder="$t('Pick a date')"
              style="width: 200px;"
            />
            <v-select
              v-model="modelFilter.supplier"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="name"
              :options="providers"
              :placeholder="$t('Supplier')"
              style="width: 200px;"
            />
          </div>
        </div>
        <div
          class="d-flex align-items-center"
          :class="{'mt-1': modelFilter.search || modelFilter.created_at || modelFilter.supplier}"
          style="gap: 8px;"
        >
          <b-badge
            v-if="modelFilter.search"
            variant="light-dark cursor-pointer"
            @click="modelFilter.search = null"
          >
            {{ modelFilter.search }}
            <span class="text-danger">X</span>
          </b-badge>
          <b-badge
            v-if="modelFilter.created_at"
            variant="light-dark cursor-pointer"
            @click="modelFilter.created_at = null"
          >
            {{ modelFilter.created_at }}
            <span class="text-danger">X</span>
          </b-badge>
          <b-badge
            v-if="modelFilter.supplier"
            variant="light-dark cursor-pointer"
            @click="modelFilter.supplier = null"
          >
            {{ modelFilter.supplier.name }}
            <span class="text-danger">X</span>
          </b-badge>
        </div>
      </b-card-header>

      <b-card-body>
        <!-- table -->
        <vue-good-table
          ref="inventory-receives"
          class="custom-good-table"
          :columns="fields"
          :rows="filterItems"
          style-class="vgt-table"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm }"
          :pagination-options="{
            enabled: true,
            perPage:pageLength
          }"
        >
          <template
            slot="table-column"
            slot-scope="props"
          >
            <span>
              {{ $t(props.column.label) }}
            </span>
          </template>
          <template
            slot="table-row"
            slot-scope="props"
          >
            <span
              v-if="props.column.field === 'code'"
            >
              <router-link :to="`/inventory-receives/edit/${props.row.id}`">{{ props.row.code }}</router-link>
              <router-link :to="`/purchasing-orders/edit/${props.row.request_goods_id}`">
                <span class="related-corner">PDH-{{ props.row.request_goods_id }}</span>
              </router-link>
            </span>

            <span
              v-else-if="props.column.field === 'barcode'"
              style="max-width: 140px;"
            >
              {{ props.row.barcode }}
              <feather-icon
                class="cursor-pointer ml-1"
                icon="CopyIcon"
                @click="doCopy(props.row.barcode)"
              />
            </span>

            <!-- Column: Status -->
            <span v-else-if="props.column.field === 'status'">
              <b-badge
                class="d-block"
                :variant="statusVariant(props.row.status)"
              >
                {{ props.row.status_label }}
              </b-badge>
            </span>

            <span
              v-else-if="props.column.field === 'price'"
              style="max-width: 140px;"
            >{{ unitFormatOriginal(props.row.price) }}</span>

            <!-- Column: Actions -->
            <span v-else-if="props.column.field === 'actions'">
              <router-link :to="`/combo/edit/${props.row.id}`">{{ $t('Detail') }}</router-link>
            </span>

            <!-- Column: Debt -->
            <span
              v-else-if="props.column.field === 'debt'"
              class="text-danger"
            >
              {{ unitFormatOriginal(props.row.debt) }}
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap ">
                  Showing 1 to
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="pageOptions"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap"> of {{ props.total }} entries </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-card-body>
    </b-card>
  </section>
</template>

<script>
/* eslint-disable array-callback-return */
import {
  // BTabs,
  // BTab
  BCard,
  BCardHeader,
  BCardBody,
  // BButton,
  BFormInput,
  // BFormGroup,
  BFormSelect,
  BBadge,
  BPagination,
  BInputGroupPrepend,
  BInputGroup,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
// import Request from '@/plugins/service/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import general from '@/mixins/index'
import VSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    // BButton,
    BFormInput,
    // BFormGroup,
    BFormSelect,
    BBadge,
    BPagination,
    BInputGroupPrepend,
    BInputGroup,
    VueGoodTable,
    // Treeselect,
    flatPickr,
    VSelect,
  },
  mixins: [general],
  data() {
    return {
      statisticsItems: [
        {
          icon: 'BoxIcon',
          color: 'light-primary',
          title: '2300',
          subtitle: 'Số lượng tồn kho',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'ShoppingCartIcon',
          color: 'light-info',
          title: '8.549M',
          subtitle: 'Tổng giá bán',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'ThumbsUpIcon',
          color: 'light-danger',
          title: '1.423M',
          subtitle: 'Tổng giá trị',
          customClass: 'mb-2 mb-sm-0',
        },
        {
          icon: 'DollarSignIcon',
          color: 'light-success',
          title: '7.126M',
          subtitle: 'Lợi nhuận',
          customClass: '',
        },
      ],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      pageOptions: [5, 10, 20, 50, 100],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageLength: 10,
      searchTerm: '',
      filterSelected: 0,
      dateRangeConfig: {
        mode: 'range',
        dateFormat: 'd-m-Y',
        maxDate: 'today',
      },
      modelFilter: {
        search: null,
        status: null,
        category_id: null,
      },
      supplierOptions: [],
      fields: [
        {
          field: 'code',
          label: 'Code',
          width: '60px',
          sortable: false,
        },
        {
          field: 'created_at',
          label: 'Created at',
          tdClass: 'text-center',
          sortable: false,
        },
        {
          field: 'supplier_name',
          label: 'Supplier',
          type: 'number',
          tdClass: 'text-center',
          sortable: false,
        },
        {
          field: 'warehouse',
          label: 'Warehouse',
          type: 'number',
          tdClass: 'text-center',
          sortable: false,
        },
        {
          field: 'price',
          label: 'Total price',
          type: 'number',
          tdClass: 'text-center',
          thClass: 'custom-th-class',
          sortable: false,
        },
        {
          field: 'debt',
          label: 'Còn nợ',
          type: 'number',
          tdClass: 'text-center',
          thClass: 'custom-th-class',
          sortable: false,
        },
      ],
      items: [
        {
          code: 'IR0707220330',
          created_at: '07/07/2022 15:30',
          supplier_name: 'B&T',
          warehouse: 'iFashion 51',
          price: 10000000,
          debt: 10000000,
        },
        {
          code: 'IR0707220332',
          created_at: '07/07/2022 15:32',
          supplier_name: 'B&T',
          warehouse: 'iFashion 51',
          price: 87000000,
          debt: 0,
        },
      ],
      providers: [],
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        0 : 'light-dark',
        1     : 'light-success',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    filterItems() {
      if (this.filterSelected === 0) return this.items
      return this.items.filter(item => item.status === this.filterSelected)
    },
  },
  watch: {
    // eslint-disable-next-line object-shorthand
    'modelFilter.created_at'() {
      this.handleFilter()
    },
    // eslint-disable-next-line object-shorthand
    'modelFilter.supplier'() {
      this.handleFilter()
    },
  },
  created() {
    this.getList()
    this.loadSupplier()
  },
  methods: {
    doCopy(text, position = 'top-center') {
      this.$copyText(text).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Copy thành công',
            icon: 'BellIcon',
          },
        },
        {
          position,
        })
      }, () => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Copy thất bại',
            icon: 'BellIcon',
          },
        })
      })
    },
    async loadSupplier() {
      const res = await this.mixGet('/suppliers', { limit: 1000 })
      if (res.status) {
        if (res.data) {
          this.providers = res.data.items
        }
      }
    },
    handleFilter() {
      const params = {}
      if (this.modelFilter.search) params.search = this.modelFilter.search
      if (this.modelFilter.created_at) params.created_at = this.modelFilter.created_at
      if (this.modelFilter.supplier) params.supplier = this.modelFilter.supplier.id_number
      this.$router.replace({
        query: params,
      }).catch(() => {})
      this.getList()
    },
    async getList() {
      const params = {
        'sort[]': 'id,desc',
      }
      if (this.$route.query.search) {
        if (this.$route.query.search.includes('PN-') || this.$route.query.search.includes('pn-')) {
          const t = this.$route.query.search.split('-')
          params.id = `${t[1]}`
        } else {
          params.id = `${this.$route.query.search}`
        }
      }
      if (this.$route.query.created_at) params.created_at = `${this.dateConvert(this.$route.query.created_at, 'year')}`
      if (this.$route.query.supplier) params.supplier = `${this.$route.query.supplier}`
      const res = await this.mixGet('/receivings_goods', params)
      if (res.status) {
        const temp = []
        res.data.items.map(val => {
          const vatTotal = (val.money_payment / 100) * val.request_good.vat
          temp.push(
            {
              id: val.id,
              request_goods_id: val.request_goods_id,
              code: `PN-${val.id}`,
              created_at: val.created_at,
              supplier_name: val.request_good.supplier.name,
              warehouse: val.request_good.storage.name,
              price: val.money_payment + vatTotal,
              debt: (val.money_payment + vatTotal) - val.money_paid,
            },
          )
          return true
        })
        this.items = temp
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style lang="sass" scoped>
.promotion-head
  .sub-title
    font-size: 12px
    color: #a6a4b0
  .item
    border-right: 1px solid #e5e5e5
    padding-top: 5px
    padding-bottom: 5px
    margin-right: 30px

    &:last-child
      border: none

    .price
      margin-top: 5px
      margin-bottom: 5px
      font-weight: bold
      font-size: 20px

  .d-grid
    display: grid
    grid-template-columns: auto auto auto auto

  .flatpickr-input
    visibility: hidden

  .sub-title
    cursor: pointer

.filter
  &-tab
    display: flex
    overflow: hidden
    flex: none
    border-bottom: 1px solid #e5e5e5

    &__item
      min-width: 64px
      padding: 0 16px
      height: 56px
      display: flex
      align-items: center
      font-weight: 500
      font-size: 14px
      border-bottom: 3px solid transparent
      cursor: pointer
      transition: all .2s

      &.active,
      &:hover
        color: var(--primary)

      &.active
        border-bottom-color: var(--primary)

.cus-group-filter
  gap: 16px

  .flex-1
    flex: 1

  .cus-group
    gap: 16px
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.related-corner {
  position: relative;
  margin-top: 4px;
  display: inline-flex;
  align-items: flex-start;
  gap: 4px;
  white-space: nowrap;

  &::before {
    content: "";
    height: 12px;
    width: 12px;
    display: block;
    border-bottom: 1px solid var(--gray);
    border-left: 1px solid var(--gray);
    border-radius: 2px;
  }
}
</style>
<style src="@riophae/vue-treeselect/dist/vue-treeselect.min.css"></style>
